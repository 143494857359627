
import React, { useEffect } from 'react';
import Layout from "../layouts/pl.js";

import { navigate } from 'gatsby';

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "pl";
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "pl";
  switch (lang) {
    case "de":
      return "de";
    case "en":
      return "en";
    default:
      return "pl";
  }
};

const Index = (props) => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();
    navigate(`/${urlLang}/`, { replace: true });
  }, []);

  return  <Layout location={props.location} />
};

export default Index
